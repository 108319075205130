<template>
  
<pre><code>
  #passos parar utilizar o componente X:
  #1-import
    <span style="color:yellow">import BButton from './components/shared/X.vue';</span>
  #2-alias na are de components
  <span style="color:yellow">'xAlias': X</span>
  <br>

  </code></pre>

<bTemplate  @actionTriggered="redir()" btname="cancelar" >

<pre><code>
  # Template deve ser informado o nome do botão e sua ação
  # ex:
  <span style="color:yellow">@actionTriggered="redir()" btname="cancelar"</span>
</code></pre>



      <whiteBox>
       <pre><code>
        #Caixa com borda, o  estilo do 
        #conteudo da caixa, e suas dimensões
        #deve ser controlado via css 
         <span style="color:yellow">'class="divt' onde divt passa os valores css</span>
       </code></pre>

      </whiteBox>


      <borderlessBox>
         <pre><code>
        #Caixa sem   borda, com slot,
        #o  estilo do 
        #conteudo da caixa, e suas dimensões
        #deve ser controlado via css 
         <span style="color:yellow">'class="divt' onde divt passa os valores css</span>
       </code></pre>

      </borderlessBox>

      <div v-show="vshow">
      <dialContainer >
         <pre><code>
          #DialogContainer
          #Dialog ex. deve estar dentro de uma diretiva v-show,
          #esta diretiva ira dizer se aparece ou nao na tela, 
          #possui slot, todo seu conteudo "texto, img, botoes" deve
          #ser estilizado com css 
          </code></pre>
        <mButton rotulo="Confirmar pagamento"
                 tipo="submit"
                 :confirmacao="false"
                 @botaoAtivado="showDialog()"/>
        <br>
        <b-button rotulo="Voltar"
                  tipo="submit"
                  :confirmacao="false"
                  @botaoAtivado="showDialog()"/>

    <br/>

      </dialContainer>
      </div>

      <div class="p-fluid ">

        <form @submit.prevent="greet" >
          <br>
          <br>
          <whiteContainer>
            <pre><code>
           #whiteContainer, com slot,
           #o  estilo do 
           #conteudo do container, e suas dimensões
           #deve ser controlado via css 
            </code></pre>
        
            <c-button rotulo="O que é Open Banking"
                      tipo="submit"
                      :confirmacao="true"
                      @botaoAtivado="remove()" />
          

          </whiteContainer>
          <br>
          
          <pre><code>
           #RedirBox, com slot,
           #o  estilo do 
           #conteudo do container, e suas dimensões
           #deve ser controlado via css 
            </code></pre>
            <pre><code>
           #RedirBox, com slot,
           #o  estilo do 
           #conteudo do container, e suas dimensões
           #deve ser controlado via css 
            </code></pre>
      
    
          <redirBox >
          
            Nuvem pay <br><br>
            <greyRec>
      
              Estamos lhe redirecionando para o ambiente<br>da instituição  selecionada
           </greyRec>
          Bratech Credito
          </redirBox>

           <br>


<pre><code>
  #PanelWBorderHistory
  #props: ftype e ftype2, controlam a fonte do titulo  e subtitulo
  #titulo e valor são strings que representam o titulo e subtitulo
  #pstyle, ststyle  e pcstyle classes que controlam o css do
  #painel,  painel titulo e painel conteudo
           
 </code></pre>



          <pbHistory :ftype="ftype" :ftype2="ftype2" :titulo="labelP2" 
          :valor="valueP2" :pstyle="p3" :ptstyle="pt3" 
          :pcstyle="pc3">
            Magazine Luiza
            <br>
            R$ 10000
          </pbHistory>
          <br>

    
<pre><code>
  #PanelBorderless
  #props: ftype e ftype2, controlam a fonte do titulo  e subtitulo
  #titulo e valor são strings que representam o titulo e subtitulo
  #pstyle, ststyle  e pcstyle classes que controlam o css do
  #painel,  painel titulo e painel conteudo
           
 </code></pre>


          <br>
          <blessPanel :ftype="ftype" :ftype2="ftype2" :titulo="labelP2" 
          :valor="valueP2" :pstyle="panel2" :ptstyle="paneltitle2" 
          :pcstyle="panelcontent2">
            
             Magazine Luiza
            <br>
            R$ 10000
          </blessPanel>
          <br>

   
<pre><code>
  #PanelWBorder
  #props:
  #titulo e valor são strings que representam o titulo e subtitulo
  #pstyle, ststyle  e pcstyle classes que controlam o css do
  #painel,  painel titulo e painel conteudo
           
 </code></pre>

          <redPanel :titulo="labelP" :valor="valueP" :pstyle="panel" :ptstyle="paneltitle" :pcstyle="panelcontent">
            PanelWBorder,
           
            props: titulo e valor são strings que representam o titulo e subtitulo <br>
                   pstyle, ststyle  e pcstyle classes que controlam o <br>
                   css do painel,  painel titulo e painel conteudo
            <div style=" margin: auto; width: 30%;">
              <div class="p-field-radiobutton">
                <RadioButton id="opt1" name="op1" value="Operadora 1" v-model="op1" />
                <label for="opt1">Operadora 1</label>
            </div>
            <div class="p-field-radiobutton">
                <RadioButton id="opt2" name="op1" value="Operadora 2" v-model="op1" />
                <label for="opt2">Operadora 2</label>
            </div>
          </div>
          </redPanel>

          <br>

             
<pre><code>
  #GreyContainer
  #Container, com slot, deve ter o conteudo  do slot estilizado
  #para se adequar ao caso de uso
           
           
 </code></pre>

          <greyContainer  style=" margin-left: 25px;margin-right: 23px;">
           Some text 
           <div class="msg">
            {{msggrey}}
           </div>
            <div class="instcss">
             {{inst}}
            </div>
          </greyContainer>


          <br/>

          <br/>

          <div class="p-field" >
           <pre><code>
            #CButton
            #botao cancelar propriedades:
            #rotulo: string com o label
            #tipo:   tipo do button 'submit, button, etc'
            #confirmacao: se mostra a janela nativa de confirmar a ação
             <span style="color:yellow">
            @botaoAtivado: methodo que controla a ação do botao 
            #ex:
            '@botaoAtivado="remove()' ao clicar no botao dispara o metodo remove</span>
             </code></pre>
            <c-button rotulo="Cancelar"
                      tipo="submit"
                      :confirmacao="true"
                      @botaoAtivado="remove()" />
          </div>

          <br/>
          <div class="p-field">
        <pre><code>
            #MButton
            #botao de confirmação:
            #rotulo: string com o label
            #tipo:   tipo do button 'submit, button, etc'
            #confirmacao: se mostra a janela nativa de confirmar a ação
             <span style="color:yellow">
            @botaoAtivado: methodo que controla a ação do botao 
            #ex:
            '@botaoAtivado="remove()' ao clicar no botao dispara o metodo remove</span>
             </code></pre>    <mButton rotulo="Lala land"
                     tipo="button"
                     :confirmacao="false"
                     @botaoAtivado="showDialog()"/>


          </div>
      <br/>

        </form>
      </div>

      <faq label="Faq" text="detalhes"/>

</bTemplate>
  

    <Toast/>
  
</template>

<script>


import Button from '../shared/Mbutton.vue';

import BButton from '../shared/Bbutton.vue';

import CButton from '../shared/Cbutton.vue';
import GreyContainer from '../shared/GreyContainer.vue';
import WhiteContainer from '../shared/WhiteContainer.vue';
import DialogContainer from "../shared/DialogContainer.vue";
import WhiteBoxBorderless from "../shared/WhiteBoxBorderless.vue";
import WhiteBox from "../shared/WhiteBox.vue";
import GreyContainerRec from "../shared/GreyContainerRec.vue";
import RedirectBox from "../shared/RedirectBox.vue";
import PanelWBorder from '../shared/PanelWBorder.vue';
import PanelWBorderHistory from '../shared/PanelWBorderHistory.vue';
import PanelBorderless from '../shared/PanelBorderless.vue';
import BasicTemplate from '../shared/BasicTemplate.vue';

import WhiteContainerFAQ from '../shared/WhiteContainerFAQ.vue';
export default {
  data() {
        return {
            labelP: 'Valor a pagar',
            labelP2:'Consumido',
            valueP:'R$1000,00',
            valueP2:'13/05/2021 - 15:32',
            message: null,
            text: null,
            bname:'',
            url:'http://www.google.com',
            msggrey:null,
            inst:null,
            panel:{},
            paneltitle:{},
            panelcontent:{},
            panel2:{},
            paneltitle2:{},
            panelcontent2:{},
            ftype:{},
            ftype2:{},
            p3:{},
            pt3:{},
            pc3:{},
            btname:'',
            vshow:false,
            op1:'',

        }
    },created() {
        this.btname='Cancelar',
        this.bname='Nome do Button',
        this.msggrey='Para que possamos concluir o compartilhamento, iremos lhe redirecionar para a instituição:',
        this.inst='Nuvem Pay',
        this.p3={
        'border': '1px solid #458FFF',
        'background-color': '#458FFF',
       
        
      }
       this.pt3={
          background: '#458FFF',
          color: 'black',
        },
          this.pc3={

            background: 'white',
            border: '3px solid #458FFF',
            'text-align':'center',

          }


        this.panel={
        'border': '1px solid #ED1846',
        'background-color': '#ED1846',
       
        
      },this.ftype={
        'font-size':'16px',

          'font-weight':'bold',

      },
       this.paneltitle={
          background: '#ED1846',
          color: 'white',
        },
          this.panelcontent={

            background: 'white',
            border: '3px solid #ED1846',

          }, this.panel2={
      
        'background-color': '#15AB3F',
      }, this.paneltitle2={
          background: '#15AB3F',
          color: '#212529',
          'padding-top':'10px'
        
        },this.ftype2={
          'font-size':'12px',
          'font-weight':'bold',
        }
        ,
          this.panelcontent2={

            background: 'white',
            
          }

  },
    methods: {
        greet() {
            this.$toast.add({severity: 'info', summary: 'Hello '  + this.text});
            this.message = 'Hello ' + this.text;
        },
      remove() {
        console.log('redirect to '+this.url);
      },
      redir(){
        console.log('execute this action');
      },
      showDialog(){
        if(this.vshow)
          this.vshow =false;
        else{
          this.vshow = true;
        }
      }
    },
    components: {
    
      'mButton': Button,
      'bButton':BButton,
      'cButton':CButton,
      'greyContainer':GreyContainer,
      'redPanel':PanelWBorder,
      'whiteContainer':WhiteContainer,
      'dialContainer':DialogContainer,
      'whiteBox':WhiteBox,
      'borderlessBox':WhiteBoxBorderless,
      'greyRec':GreyContainerRec,
      'redirBox':RedirectBox,
      'blessPanel':PanelBorderless,
      'pbHistory':PanelWBorderHistory,
      'bTemplate':BasicTemplate,
      'faq':WhiteContainerFAQ,
    },

}
</script>

<style scoped>

.msg{
  color: #414141;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}
.instcss{
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: #464646;


}
.divt{
  width: 138.34px;
  
}

.img-sett{
  padding-top: 3%;
  padding-bottom: 3%;

}

img {
  border: 0px;
  border-radius: 4px;
  width: 50px;

}

.wraper-text{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;



}
 .div1Style{
      font-Weight: 700;
      font-Style: normal;
      font-Size :32px;
      Line-height :32px;
      text-align:center;
      color:#3C3C3C;


    }
    .div2Style{
          font-Weight: 400;
          font-Style :normal;
          font-Size: 16px;
          Line-height: 24px;
          text-align :Center;
          color: #3C3C3C
        }
.separator{
  margin-top: 24px;
  margin-bottom: 24px;
}

pre code {
    scrollbar-color: #666 #201c29;
    background: 0 0;
    white-space: pre;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    display: block;
    max-width: 100%;
    min-width: 100px;
    padding: 15px 20px 12px 22px;
    line-height: 1.75;
    font-size: 11px;
}

pre {
    clear: both;
    color: #fff;
    background: #1b1b1b;
    padding: 0;
    -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2;
    -ms-word-break: normal;
    word-break: normal;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    position: relative;
    line-height: 28px;
    border-radius: 8px;
    min-width: 100px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}


.imp{
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 32px;

/* identical to box height, or 133% */
text-align: center;

color: #464646;
}

.imp2{
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;

/* or 133% */
text-align: center;

color: #414141;


}


</style>