<template>

  <div :style="whiteContainer">
    <slot></slot>
  </div>

</template>

<script>

export default {
  data() {
    return {
      whiteContainer: {}
    }
  },
  created() {
    this.whiteContainer = {
      'background': 'var(--page-background-color)',
      'border': '3px solid var(--confcons-box2-border-color)',
      'box-sizing': 'border-box',
      'box-shadow': 'var(--shadow-default)',
      'border-radius': '15px',
      'display':'inline-block',
    }
  }
}
</script>

<style scoped>
</style>