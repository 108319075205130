<template>

  <top-bar @triggerAction="actionTriggered()" :btname="btname"/>
  <div class="btStyle">
    <slot>
    </slot>
  </div>

</template>

<script>
import TopBar from "./TopBar.vue";

  export default {
    props:['btname'],
    data(){
      return {
      }
    },

    methods: {
       actionTriggered() {
      this.$emit('actionTriggered');
    }

    },
    components:{
      'top-bar':TopBar,
    }
  }
</script>

<style scoped>

.btStyle{
  padding: 17px 23px 25px 24px;
}

</style>