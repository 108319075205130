<template>

  <div :style="whiteContainer">
    <slot></slot>
  </div>

</template>

<script>

export default {
  data() {
    return {
      whiteContainer: {}
    }
  },
  created() {
    this.whiteContainer = {
      'background': 'var(--page-background-color)',
      'box-shadow': 'var(--shadow-default)',
      'border-radius': '8px',
    }
  }
}
</script>

<style scoped>
</style>