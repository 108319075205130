<template>

  <div :style="whiteContainer" class="temp">
    <slot></slot>
  </div>

</template>

<script>

export default {
  data() {
    return {
      whiteContainer: {}
    }
  },
  created() {
    this.whiteContainer = {
      'background': 'var(--exceptions-backgrounddown-solid-color)',
      'border-radius': '8px',
    }
  }
}
</script>

<style scoped>

.temp{
  width: 312px;
  height: 256px;
  left: 24px;
  top: 296px;
}

</style>

