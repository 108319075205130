<template>

  <Toolbar :style="topnav">
    <template #left>
      <Button label="Voltar" class="p-mr-2" :style="barbutton" @click="triggerAction()">
        <i class="pi pi-angle-left" style="fontSize: 2rem;" :style="arrowp"></i>
        <div :style="barbutton"> {{btname}}</div>
      </Button>
    </template>

    <template #right>
      <img alt="logo" :src="imgsrc" height="27" width="24" class="p-mr-2 img-sett" :style="imgbg">

      <div class="wraper-bank-name" >
        <div :style="bankname" style="margin-top: 10px">
          {{bname}}
        </div>
      </div>

    </template>
  </Toolbar>

</template>

<script>


export default {
  props:['btname'],
  name: "TopBar",
  data(){
    return {
      imgsrc: '',
      bname:'',
      aName:'',
      topnav:{},
      bankname:{},
      imgbg:{},
      barbutton:{},
      arrowp:{}
    }
  },
  created() {
    this.imgsrc = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png';
    this.bname  = 'BANCO';
    this.aName  = 'Voltar';
    this.topnav={
      backgroundColor:'#414141',
    }
    this.bankname={
      'font-weight':'700',
        color: '#FFFFFF',
      'font-size':'18px'
    }
    this.imgbg={
      'background-color':'#FFFFFF',
    }
    this.barbutton={
      'background-color':'inherit',
      padding: '0',
      border: 'none',
      'font-size': '14px',
      'font-weight':'bold',
    }
    this.arrowp={
      color: 'white',
    }
  },
  methods:{
    triggerAction() {
      this.$emit('triggerAction');
    }
  }
}
</script>

<style scoped>

.img-sett{
  margin-top: 10%;
  margin-bottom: 5%;
}
.wraper-bank-name{
  float: right;
  padding: 8px;
}

</style>